import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MenuModel } from '../../shared/models/menu-parent-section.model';
import { availableSections } from '../../shared/menu-available-sections/available-sections';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userRoles: string[] = [];
  private userScopes: string[] = [];

  constructor(private oauthService: OAuthService) {
    this.loadUserRoles();
  }

  getUserAllowedSections(): MenuModel {
    const userRoles = this.userRoles;

    const userSections = availableSections
      .filter(
        section =>
          section.items.some(item =>
            userRoles.includes(`${item.permissionName}.view`),
          ), // Removing parent section if the user has no permission to access any subsection
      )
      .map(section => {
        // Removing child item if the user has no permission to read it
        return {
          ...section,
          items: section.items.filter(item =>
            userRoles.includes(`${item.permissionName}.view`),
          ),
        };
      });
    return userSections;
  }

  loadUserRoles(): string[] {
    const userScopes = this.oauthService.getIdentityClaims()[
      'role'
    ] as string[];
    this.userScopes = userScopes;
    const userRoles = this.mapScopesIntoRoles(userScopes);
    this.userRoles = userRoles;

    return userRoles;
  }

  checkUserHasRole(role: string): boolean {
    return this.userRoles.includes(role);
  }

  checkUserHasScope(scope: string): boolean {
    return this.userScopes.includes(scope);
  }

  private mapScopesIntoRoles(scopes: string[]): string[] {
    const roles: string[] = [];
    scopes.forEach(scope => {
      switch (scope.toLowerCase()) {
        case 'sistemas':
          roles.push(
            ...[
              'masters.sites.view',
              'masters.sites.edit',
              'masters.sites.delete',
              'masters.sites.create',
              'masters.distributors.view',
              'masters.distributors.edit',
              'masters.distributors.delete',
              'masters.distributors.create',
              'masters.calculationMatrix.view',
              'masters.calculationMatrix.edit',
              'masters.calculationMatrix.delete',
              'masters.calculationMatrix.create',
              'masters.countries.view',
              'masters.countries.edit',
              'masters.countries.delete',
              'masters.countries.create',
              'masters.companies.view',
              'masters.companies.edit',
              'masters.companies.delete',
              'masters.companies.create',
              'masters.taxes.view',
              'masters.taxes.edit',
              'masters.taxes.delete',
              'masters.taxes.create',
              'masters.postcodes.view',
              'masters.postcodes.edit',
              'masters.postcodes.delete',
              'masters.postcodes.create',
              'masters.users.view',
              'masters.users.edit',
              'masters.users.delete',
              'masters.users.create',
              'masters.deliveryModes.view',
              'masters.deliveryModes.edit',
              'masters.indicators.view',
              'masters.indicators.edit',
              'masters.departments.view',
              'masters.departments.edit',
              'masters.departments.delete',
              'masters.departments.create',
            ],
          );
          break;
        case 'producto':
          roles.push(
            ...[
              'productManagement.distributors.view',
              'productManagement.distributors.edit',
              'productManagement.distributors.delete',
              'productManagement.distributors.create',
              'productManagement.products.view',
              'productManagement.products.edit',
              'productManagement.products.delete',
              'productManagement.products.create',
              'productManagement.categories.view',
              'productManagement.colors.view',
              'productManagement.colors.edit',
              'productManagement.colors.delete',
              'productManagement.colors.create',
              'productManagement.syncProducts.view',
              'productManagement.syncProducts.edit',
              'productManagement.syncProducts.delete',
              'productManagement.syncProducts.create',
            ],
          );
          break;
        case 'comercial':
          roles.push(
            ...[
              'sales.customers.view',
              'sales.customers.edit',
              'sales.customers.delete',
              'sales.customers.create',
              'sales.budgets.view',
              'sales.budgets.edit',
              'sales.budgets.delete',
              'sales.budgets.create',
              'sales.pendingAction.view',
              'sales.pendingAction.edit',
              'sales.pendingAction.delete',
              'sales.pendingAction.create',
              'sales.myProjects.view',
              'sales.myProjects.edit',
              'sales.myProjects.delete',
              'sales.myProjects.create',
            ],
          );
          break;
        case 'administracion':
          roles.push(
            ...[
              'administration.invoicing.view',
              'administration.invoicing.edit',
              'administration.invoicing.delete',
              'administration.invoicing.create',
              'administration.invoiceReview.view',
              'administration.invoiceReview.edit',
              'administration.invoiceReview.delete',
              'administration.invoiceReviews.create',
            ],
          );
          break;
        case 'produccion':
          roles.push(
            ...[
              'production.printingMethods.view',
              'production.printingMethods.edit',
              'production.printingMethods.delete',
              'production.printingMethods.create',
              'production.workingFlows.view',
              'production.workingFlows.edit',
              'production.workingFlows.delete',
              'production.workingFlows.create',
              'production.pendingRevision.view',
              'production.pendingRevision.edit',
              'production.pendingRevision.delete',
              'production.pendingRevision.create',
            ],
          );
          break;
        case 'operarios-produccion':
          roles.push(
            ...['projects.tasks.view', 'projects.projectDetails.view'],
          );
          break;
      }
    });

    return roles;
  }
}
