import { MenuModel } from '../models/menu-parent-section.model';

export const availableSections: MenuModel = [
  {
    icon: 'tune',
    name: 'Parámetros globales',
    uri: 'masters',
    items: [
      {
        permissionName: 'masters.sites',
        name: 'Sitios',
        uri: 'sites',
      },
      {
        permissionName: 'masters.calculationMatrix',
        name: 'Matriz de cálculo',
        uri: 'calc-matrix',
      },
      {
        permissionName: 'masters.companies',
        name: 'Empresas',
        uri: 'companies',
      },
      {
        permissionName: 'masters.countries',
        name: 'Países',
        uri: 'countries',
      },
      {
        permissionName: 'masters.taxes',
        name: 'Impuestos',
        uri: 'taxes',
      },
      {
        permissionName: 'masters.postcodes',
        name: 'Códigos postales',
        uri: 'postcodes',
      },
      {
        permissionName: 'masters.users',
        name: 'Usuarios',
        uri: 'users',
      },
      {
        permissionName: 'masters.deliveryModes',
        name: 'Modos de envío',
        uri: 'delivery-modes',
      },
      {
        permissionName: 'masters.indicators',
        name: 'Indicadores',
        uri: 'indicators',
      },
      {
        permissionName: 'masters.departments',
        name: 'Departamentos',
        uri: 'departments',
      },
    ],
  },
  {
    icon: 'inventory_2',
    name: 'Catálogo',
    uri: 'product-management',
    items: [
      {
        permissionName: 'productManagement.distributors',
        name: 'Distribuidores',
        uri: 'distributors',
      },
      {
        permissionName: 'productManagement.syncProducts',
        name: 'Sincronizar productos',
        uri: 'sync-products',
      },
      {
        permissionName: 'productManagement.products',
        name: 'Productos',
        uri: 'products',
      },
      {
        permissionName: 'productManagement.categories',
        name: 'Categorías',
        uri: 'categories',
      },
      {
        permissionName: 'productManagement.colors',
        name: 'Colores',
        uri: 'colors',
      },
    ],
  },
  {
    icon: 'shopping_cart',
    name: 'Ventas',
    uri: 'sales',
    items: [
      {
        permissionName: 'sales.customers',
        name: 'Clientes',
        uri: 'customers',
      },
      {
        permissionName: 'sales.budgets',
        name: 'Mis oportunidades',
        uri: 'budgets',
      },
      {
        permissionName: 'sales.budgets',
        name: 'Nueva oportunidad',
        uri: 'budgets/create',
      },
      {
        permissionName: 'sales.pendingAction',
        name: 'Acción comercial',
        uri: 'pending-action',
      },
      {
        permissionName: 'sales.myProjects',
        name: 'Mis proyectos',
        uri: 'my-projects',
      },
    ],
  },
  {
    icon: 'factory',
    name: 'Producción',
    uri: 'production',
    items: [
      {
        permissionName: 'production.printingMethods',
        name: 'Técnicas de marcaje',
        uri: 'printing-methods',
      },
      {
        permissionName: 'production.workingFlows',
        name: 'Flujos de trabajo',
        uri: 'working-flows',
      },
      {
        permissionName: 'production.pendingRevision',
        name: 'Pendientes de revisión',
        uri: 'pending-revision',
      },
    ],
  },
  {
    icon: 'account_balance',
    name: 'Administración',
    uri: 'administration',
    items: [
      {
        permissionName: 'administration.invoicing',
        name: 'Facturación',
        uri: 'invoicing',
      },
      {
        permissionName: 'administration.invoiceReview',
        name: 'Conformar',
        uri: 'invoice-review',
      },
    ],
  },
  {
    icon: 'task',
    name: 'Proyectos',
    uri: 'projects',
    items: [
      {
        permissionName: 'projects.tasks',
        name: 'Tareas',
        uri: 'tasks',
      },
      {
        permissionName: 'projects.projectDetails',
        name: 'Detalles de proyecto',
        uri: 'project-details',
      },
    ],
  },
];
